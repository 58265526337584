@import "system/variables";
/******  HELPERS  ******/
.color-brand {
    color: $brand;
  }

  .color-white {
    color: #fff;
  }
  .color-sec{
color: $secondary;
  }
  .color-green{
    color: #7ECDCA
  }
  .color-red{
color: #C0575E;
  }
  .text-center {
    text-align: center;
  }
.big-text{
  @include vw($font-size: 32);
  font-family: $sec-bold;
  line-height: 1;
  @include respond-to(xs){
    font-size: 2.7vh;
  }

}
  .flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.w-100 {
    width: 100%;
}
