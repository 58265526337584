
@font-face {
    font-family: 'Proxima Nova Semibold';
    src:
      url('/fonts/ProximaNova/ProximaNovaSemibold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Regular';
    src:
      url('/fonts/ProximaNova/ProximaNovaRegular.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Light';
    src:
      url('/fonts/ProximaNova/ProximaNovaLight.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Bold';
    src:
      url('/fonts/ProximaNova/ProximaNovaBold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-96BlkIt';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSStd-96BlkIt.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-95Blk';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSPro-95Blk.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-66MdIt';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSStd-66MdIt.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-65Md';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSStd-65Md.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-75Bd';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSStd-75Bd.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NHaasGroteskDSPro-55Rg';
    src:
      url('/fonts/NHaasGroteskDS/NHaasGroteskDSStd-55Rg.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}