@import 'system/variables';
@import 'system/mixin';
.slick-next:before, .slick-prev:before{
  color: $secondary-dark;
  // content: '→';
  @include vw($font-size: 100);
}
.slick-next{

  transform: translate(110%, -50%);
  right: 0;
  width: auto;
}
.slick-prev{

  transform: translate(-110%, -50%);
  left: 0;
  width: auto;
}
.arrow{
  display: none;
  @include respond-to(xs){
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 35px;
    height: 35px;
    // border: 2px solid $grey;
    color: #fff;
    background:  rgba(0,0,0,0.2);
    opacity: 0.6;
    top: 50%;
    z-index: 9999;
    border-radius: 50%;
    font-size: 20px;;
    transform: translateY(-50%);
&-next{
  right: 0;
}
  }
}
.turn-page{
  background: #fff;
  background-repeat: no-repeat;
  }
.content-width {
  @include vw($width: 593, $padding: 0 30, $margin: 0 auto);
  max-width: 100%;
  @include respond-to(xs){
    padding: 0 3vh;
  }
}
.title {
  @include vw($font-size: 54, $margin-bottom: 30);
  color: $brand;
  font-family: $sec-black;
  line-height: 1.05;
  @include respond-to(xs){
font-size: 6vh;;
margin-bottom: 2vh;
  }
  span{
    line-height: 1.05;
  }
  &-note {
    font-family: $sec-bold;
    display: block;
    @include vw(
      $font-size: 14,
      $margin-left: 50,
      $margin-top: -30,
      $margin-bottom: 13
    );
    @include respond-to(xs){
      font-size: 2vh;;
      margin-bottom: 1vh;
      margin-top: -2vh;
      margin-left: 5vh;
        }
  }
  &--img{
    display: flex;
    align-items: center;
    @include vw($margin-bottom: 10);
    @include respond-to(xs){
      margin-bottom: 2vh
    }
  }
  &__img{
    max-width: 100%;
    @include vw($margin-left: 65, $width: 175);
    @include respond-to(xs){
      margin-left: 3vh;
      width: 17vh;
    }
  }

}
.text-md{
  color: $brand;
  font-family: $sec-med;
  @include vw($font-size: 18,
  $margin: 20 auto);
  @include respond-to(xs){
    font-size: 2vh;
    margin: 1vh 0;
  }
}
.book{
  @include vw(
    // $width: 1190,
    // $height: 842,
    $width: 595,
    $height: 842,
    // $border: 1 solid $light,
    $margin: 30 auto,
  );

  box-shadow: 0 4px 10px #666;
  @include respond-to(xs){
    width: 100vw;
    height: 100vh;
    margin: 0 auto 0;
  }
}
.shadow{
  -webkit-box-shadow: 0 4px 10px #666;
  -moz-box-shadow: 0 4px 10px #666;
  -ms-box-shadow: 0 4px 10px #666;
  -o-box-shadow: 0 4px 10px #666;
  box-shadow: 0 4px 10px #666;
}
.page {

  background:  #fff;
  box-shadow:0 0 20px rgba(0,0,0,0.2);
  @include vw(
    $width: 595,
    $height: 842
  );
  perspective: 2000px;
  position: relative;
  // overflow: hidden;
  @include respond-to(xs){
    width: 100vw;
    height: 100vh;

  }
  &--blue {
    background: $secondary;
  }
  &--bg{
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 85%,0% 100%);
      background:  $brand-light;
      @include vw($height: 302);
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    @include vw($padding: 30 0);
    @include respond-to(xs){
      padding: 3vh 0;
    }
  }
  &__title {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 0;
    &-header {
      @include vw($font-size: 300);
      font-family: $sec-bold;
      text-transform: uppercase;
      //  color: rgba(255,255,255,.26);
      opacity: 0.26;
      line-height: 1;
      position: absolute;
      // left: 50%;
      transform: translate(-3%, -15%);
      @include respond-to(xs){
        font-size: 28vh;
        left: 50%;
        transform: translate(-50%, -15%);
      }
      &--white {
        color: #fff;
      }
      &--grey {
        color: $secondary-dark;
      }
      &--light {
        color: #C7C9CB;
      }
    }
    &-footer {
      @include vw($font-size: 300);
      font-family: $sec-bold;
      text-transform: uppercase;
      line-height: 1;
      opacity: 0.26;
      position: absolute;
      // left: 50%;
      bottom: 0;
      transform: translate(-3%, 20%);
      @include respond-to(xs){
        font-size: 28vh;
        left: 50%;
        transform: translate(-50%, 20%);
      }
      &--white {
        color: #fff;
      }
      &--grey {
        color: $secondary-dark;
      }
      &--light {
        color: #C7C9CB;
      }
    }
  }
}

.page-cover {
  background: url(/images/cover.jpg) center center no-repeat;
  background-size: cover;
  &__header {
    color: #fff;
    text-align: center;
    @include vw($margin-top: 125);
    @include respond-to(xs){
      margin-top: 20vh;
    }
  }
  &__suptitle {
    display: block;
    font-family: $ff-light;
    @include vw($font-size: 32, $letter-spacing: 6);
    @include respond-to(xs){
      font-size: 5vh;
      letter-spacing: 1vh;
    }
  }
  &__title {
    display: block;
    font-family: $ff-bold;
    @include vw($font-size: 110, $margin-top: -25);
    @include respond-to(xs){
      font-size: 20vw;
      margin-top: -1vh;
    }
  }
  &__subtitle {
    display: block;
    font-family: $ff;
    @include vw($font-size: 24, $margin-top: -10);
    @include respond-to(xs){
      font-size: 3.5vh;
      margin-top: -0.5vh;
    }
  }
}
.content-table {
  width: 86%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &__title {
    @include vw($font-size: 24);
    font-family: $sec-black;
    @include respond-to(xs){
      font-size: 3vh;
      // font-family: $sec-bold;
    }
  }
  &__page {
    @include vw($font-size: 48, $right: 25);
    line-height: 1;
    position: absolute;
    // right: 0;
    @include respond-to(xs){
      font-size: 4vh;
      right: 2vh;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    font-family: $sec-black;
    align-items: center;
    text-decoration: none;
    color: #fff;
    position: relative;
    background: $text;
    @include vw(
      $padding-left: 30,
      $padding-right: 20,
      $margin-bottom: 10,
      $min-height: 40
    );
    @include respond-to(xs){
      padding-left: 3vh;
      padding-right: 8vh;
      margin-bottom: 1vh;
      min-height: 4.3vh;
    }
    &--extra-margin {
      @include vw($margin-bottom: 25);
      @include respond-to(xs){
        margin-bottom: 2vh;
      }
    }
    &--md {
      flex-basis: 94%;
      background: $brand-dark;
      @include vw($padding-left: 20);
      @include respond-to(xs){
        padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs){
          font-size: 2.5vh;
        }
      }
    }
    &--reg {
      flex-basis: 88%;
      background: $brand;
      @include vw($padding-left: 20);
      @include respond-to(xs){
        padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs){
         font-size: 2.5vh;
        }
      }
      .content-table__page {
        @include vw($font-size: 42);
        font-family: $sec-bold;
        @include respond-to(xs){
          font-size: 3.7vh;
         }
      }
    }
    &--sm {
      flex-basis: 82%;
      background: $secondary-dark;
      @include vw($padding-left: 20);
      @include respond-to(xs){
        padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs){
          font-size: 2.5vh;
        }
      }
      .content-table__page {
        @include vw($font-size: 34);
        font-family: $sec-bold;
        color: $grey;
        @include respond-to(xs){
          font-size: 3.5vh;
         }
      }
    }
    &--xs {
      flex-basis: 77%;
      background: $secondary;
      color: $grey;
      @include vw($padding-left: 20);
      @include respond-to(xs){
        padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-med;
        @include respond-to(xs){
          font-size: 2.5vh;
        }
      }
      .content-table__page {
        @include vw($font-size: 24);
        font-family: $sec-bold;
        color: $grey;
        @include respond-to(xs){
          font-size: 3vh;
         }
      }
    }
  }
}
.page-full {
  position: relative;
  &:after {
    content: '';
    @include vw($height: 233);
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(12, 13, 13, 1) 100%
    );
    @include respond-to(xs){
      height: 26vh;
    }
  }
  .content-width {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--daley {
    background: url('/images/daley.png') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: space-between;
    }
  }
  &--ashley {
    justify-content: space-between;
    background: url('/images/ashley.png') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: space-between;
    }
  }
  &--calor{
    justify-content: space-between;
    background: url('/images/cover.jpg') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: center;
    }
  }
}

.quote {
  position: relative;
  @include vw($margin-bottom: 14);
  @include respond-to(xs){
    margin-bottom: 4vh
  }
  &__sign {
    @include vw($font-size: 118);
    color: $secondary;
    font-family: $sec-black;
    position: absolute;
    transform: translateY(-56%);
    @include respond-to(xs){
      font-size:16vh;
    }
    &--lg {
      background: url('/images/quote.png') center center no-repeat;
      background-size: contain;
      @include vw($width: 204, $height: 150);
      transform: translateY(5%);
      z-index: -1;
@include respond-to(xs){
  width: 19vh;
  height: 14vh;
}
    }
  }
  &__text {
    color: #fff;
    font-family: $sec-black-it;
    @include vw($font-size: 18, $margin-bottom: 0);
    line-height: 1.4;
    @include respond-to(xs){
      font-size: 3vh;
    }
  }
  &--xl{
    .quote__sign{
      @include vw($font-size: 119);
      @include respond-to(xs){
        font-size: 17vh
      }
    }
    .quote__text{
      @include vw($font-size: 54);
      text-align: center;
      line-height: 1;
      @include respond-to(xs){
        font-size: 6vh;
      }
    }
  }
}
.about {
  @include vw($font-size: 12);
  @include respond-to(xs){
    font-size: 1.3vh;
  }
  &__name {
    color: $brand;
    @include vw($margin-bottom: 0);
    font-family: $sec-bold;
  }
  &__text {
    color: $secondary;
    font-family: $sec;
    line-height: 1.2;
    @include vw($margin-bottom: 0);
  }
}

.video {
  @include vw($width: 465, $margin: 0 auto 30);
  position: relative;
  max-width: 100%;
  @include respond-to(xs){
    width: 80%;
    margin-bottom: 5vh;
  }
  img {
    display: block;
    width: 100%;
  }
  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    @include vw($font-size: 21, $margin-bottom: 10);
    font-family: $sec-bold;
    color: #fff;
    flex-basis: 100%;
    margin-top: auto;
    text-align: center;
    @include respond-to(xs){
      font-size: 2.7vh;
    }
  }
  &-btn {
    @include vw(
      $width: 76,
      $height: 76,
      $font-size: 40,
      $border: 7 solid #fff,
      $padding-left: 6
    );
    margin-bottom: auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    transition: all 0.6s ease;
    transform: scale(0.8);
    transform: scale(1);
    @include respond-to(xs){
      width: 5vh;
      height: 5vh;
      font-size: 2vh;
      border: 0.41vh solid #fff;
      padding-left: 8;
    }
    i {
      color: #fff;
    }
  }
  &-descr {
    @include vw($width: 465, $margin: 0 auto 0);
    max-width: 100%;
    @include respond-to(xs){
      width: 80%;
    }
    &__title {
      color: $grey;
      @include vw($font-size: 18);
      font-family: $sec-med-norm;
      @include respond-to(xs){
        font-size: 2.3vh;
      }
    }
    &__text {
      color: #fff;
    }
    &__links {
      color: $grey;
      @include vw($font-size: 18);
      font-family: $sec-med-norm;
      text-align: center;
      @include respond-to(xs){
        font-size: 2.3vh;
      }
    }
  }
}
